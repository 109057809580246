









import Vue from "vue";
import Component from "vue-class-component";
import WebplayerLayout from "./components/WebplayerLayout.vue";
import { playlistService } from "./services/playlist.service";

@Component({
  components: {
    WebplayerLayout,
  },
})
export default class App extends Vue {
  name = "App";
  loaded = false;
  async mounted() {
    // await playlistService.loadSafe4();
    this.loaded = true;
    playlistService.on("safe4-updated", () => {
      this.setTrackingAndCss();
    });
  }

  setTrackingAndCss() {
    const trackingCodeHead = document.getElementById("tracking-code-head")!;
    const trackingCodeBody = document.getElementById("tracking-code-body")!;
    const externalCssStyle = document.getElementById("external-css-style")!;
    trackingCodeHead.innerText = playlistService.getTrackingCodeHead()?.replaceAll("\r\n", "") || "";
    trackingCodeBody.innerText = playlistService.getTrackingCodeBody()?.replaceAll("\r\n", "") || "";
    externalCssStyle.innerText = playlistService.getCssStyling()?.replaceAll("\r\n", "") || "";
  }
}
