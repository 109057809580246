














import { MediaItem } from "@/interfaces/media-item";
import { PlayerActions } from "@/store/store.actions";
import { PlayerGetters } from "@/store/store.getters";
import Component from "vue-class-component";
import { Ref, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { urlParamService } from "../../services/urlParam.service";
import BaseMedia from "./BaseMedia.vue";

@Component({})
export default class VideoMedia extends BaseMedia implements MediaItem {
  url = "";
  posterUrl = "";
  loaded = false;
  isPlaying = false;
  @Ref() mediaItem!: HTMLVideoElement;

  @Getter(PlayerGetters.muted) muted!: boolean;

  @Action(PlayerActions.triggerPlaylistEntry) triggerPlaylistEntry!: ({
    prev,
    triggered,
    prevIndex,
    index,
  }: {
    prev: boolean;
    triggered: boolean;
    index?: number;
    prevIndex?: number;
  }) => Promise<void>;

  mounted() {
    this.url = this.mediaData.contentItem.url;
    this.posterUrl = this.mediaData.contentItem.image_url || "";
    if (this.posterUrl && urlParamService.useCdn()) {
      this.posterUrl = `https://cdn.contentconvert.alphaport.at/api/v1/image/h_${
        this.calculatedHeight || 720
      },q_80,f_cover/resized.webp?url=${encodeURIComponent(this.posterUrl)}`;
    }
    this.mediaItem?.pause();
    this.loaded = true;
  }

  async startMedia() {
    await this.$nextTick();
    this.mediaItem.classList.add("active");

    if (!this.paused && this.loaded) {
      console.log("VideoMedia::startMedia()");

      try {
        //console.log("startMedia() play() before");
        //this.mediaItem.currentTime = 0;
        this.mediaItem.load();
        await this.mediaItem.play();
        //console.log("startMedia() play() after");
      } catch (err) {
        console.error("Error occured!", err);
        // this.$emit("mediaNotStarted");
      }
    }
  }

  async stopMedia() {
    console.log("VideoMedia::stopMedia()");
    await this.$nextTick();

    if (!this.alwaysVisible) {
      this.mediaItem?.classList.remove("active");
    }
    //console.log("stopMedia() pause() before");
    this.mediaItem.pause();
    //HINT: if you call this on ocilion STB, the video is started again -> thats why we use load()
    //this.mediaItem.currentTime = 0;
    this.mediaItem.load();
    //console.log("stopMedia() pause() after");
  }

  @Watch("paused")
  async pausedWatch(paused: boolean) {
    console.log(this.isError, this.mediaItem, this.active);
    if (this.isError) return;
    if (paused) {
      this.mediaItem.pause();
    } else if (this.active) {
      await this.mediaItem.play();
    }
  }

  async videoEndedEvent() {
    console.log("VideoMedia::videoEndedEvent() - triggerPlaylistEntry", new Date().getTime());
    console.log(this.mediaItem.duration, this.mediaItem.currentTime, this.isPlaying);
    await this.triggerPlaylistEntry({ prev: false, triggered: false });
  }

  get videoBorderSize() {
    return urlParamService.layout === "iptv" && !this.fullscreen ? 8 : 0;
  }
}
