




































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { Playlist } from "../interfaces/playlist";
import { PlayerActions } from "../store/store.actions";
import { PlayerGetters } from "../store/store.getters";

@Component({})
export default class MediaControls extends Vue {
  name = "MediaControls";
  playerMuted = true;
  show = true;
  @Prop() hover!: boolean;

  @Action(PlayerActions.toggleFullscreen) toggleFullscreen!: () => void;
  @Action(PlayerActions.togglePaused) togglePaused!: () => void;
  @Action(PlayerActions.toggleMuted) toggleMuted!: () => void;
  @Action(PlayerActions.triggerPlaylistEntry) triggerPlaylistEntry!: ({
    prev,
    triggered,
  }: {
    prev: boolean;
    triggered: boolean;
  }) => Promise<void>;

  @Getter(PlayerGetters.audioPlaylist) audioPlaylist!: Playlist;
  @Getter(PlayerGetters.paused) paused!: boolean;
  @Getter(PlayerGetters.totalTime) totalTime!: number;
  @Getter(PlayerGetters.currentTime) currentTime!: number;
  @Getter(PlayerGetters.muted) muted!: boolean;

  get progressBarValue() {
    return (this.currentTime / this.totalTime) * 100;
  }
}
