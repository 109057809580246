





















import { MediaItem } from "@/interfaces/media-item";
import { PlayerActions } from "@/store/store.actions";
import Hls, { Events } from "hls.js";
import Vue from "vue";
import Component from "vue-class-component";
import { Action } from "vuex-class";
import * as timer from "worker-timers";
import VideoMedia from "./VideoMedia.vue";

@Component({})
export default class VideoStreamMedia extends VideoMedia implements MediaItem {
  isSupported = false;
  url = "";
  loadingTimer = -1;
  hls?: Hls;

  @Action(PlayerActions.loadPlaylist) loadPlaylist!: ({
    playlistId,
    initialMasterPlaylist,
  }: {
    playlistId?: number;
    initialMasterPlaylist: boolean;
  }) => Promise<void>;

  async mounted() {
    this.isSupported = Hls.isSupported();
    this.url = this.mediaData.contentItem.url;
    await Vue.nextTick();
    if (this.mediaItem.canPlayType("application/vnd.apple.mpegurl")) {
      this.mediaItem.src = this.url;
      //
      // If no native HLS support, check if HLS.js is supported
      //
    } else {
      try {
        await this.loadHlsSource();
        this.loaded = true;
      } catch (err) {
        this.isError = true;
      }
    }
  }

  async loadHlsSource() {
    this.hls = new Hls();
    if (this.isSupported) {
      return new Promise<void>((resolve, reject) => {
        this.hls?.loadSource(this.url);
        this.hls?.attachMedia(this.mediaItem);
        this.hls?.on(Events.MANIFEST_PARSED, async () => {
          this.isError = false;
          await this.$nextTick();
          if (!this.paused) {
            if (this.mediaItem) {
              this.mediaItem.classList.toggle("active");
              this.mediaItem.style.opacity = "1";
              await this.mediaItem.play();
            }
          }
          resolve();
        });
        this.hls?.on(Events.ERROR, async (event) => {
          this.hls?.destroy();
          this.loadingTimer = timer.setTimeout(async () => await this.loadHlsSource(), 5000);
          this.isError = true;
          reject(event);
        });
      });
    }
  }
}
