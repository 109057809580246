import Vue from "vue";
import Vuex from "vuex";
import * as timer from "worker-timers";
import { playlistService } from "../services/playlist.service";
import { playlistTimer } from "../services/timer";
import { urlParamService } from "../services/urlParam.service";
import { PlayerState } from "./playerstate.types";
import { actions, PlayerActions } from "./store.actions";
import { getters, PlayerGetters } from "./store.getters";
import { mutations, PlayerMutations } from "./store.mutations";

Vue.use(Vuex);

export const store = new Vuex.Store<PlayerState>({
  state: {
    playlist: [],
    //audioPlaylist: {},
    fullscreen: false,
    currentTime: 0,
    lastTime: 0,
    paused: !urlParamService.useAutostart(),
    muted: urlParamService.isAudioMuted(),
    previousIndex: -1,
    currentIndex: -1,
    nextIndex: -1,
    triggered: false,
    focused: false,
    fullscreenElement: document.body,
  },
  mutations,
  actions,
  getters,
});

timer.setInterval(() => store.dispatch(PlayerActions.updateCurrentTime), 350);

playlistService.on("playlist-updated", async (event) => {
  playlistTimer.clearAll();
  //console.log("handle event", event);
  await store.dispatch(PlayerActions.loadPlaylist, { playlistId: event.playlistId });
  await store.dispatch(PlayerActions.triggerPlaylistEntry, { index: 0, prevIndex: 0, triggered: true });
  if (store.state.paused) {
    await store.dispatch(PlayerActions.setPaused, false);
  }
});

playlistService.on("safe4-updated", async () => {
  playlistTimer.clearAll();
  //console.log("new safe4 available");
  await store.dispatch(PlayerActions.loadPlaylist);
  await store.dispatch(PlayerActions.triggerPlaylistEntry, { index: 0, prevIndex: 0, triggered: true });
});

document.addEventListener("fullscreenchange", () => {
  if (urlParamService.layout === "iptv") {
    return;
  }
  store.commit(PlayerMutations.setFullscreen, !store.getters[PlayerGetters.fullscreen]);
});

window.addEventListener("keydown", async (event: KeyboardEvent) => {
  if (urlParamService.layout === "iptv" && parent && urlParamService.looseFocusKeys.includes(event.key) && !store.state.fullscreen) {
    store.commit(PlayerMutations.setFocused, false);
    parent.focus();
    return;
  }
  switch (event.key) {
    case "Enter":
      if (urlParamService.layout === "iptv") {
        store.dispatch(PlayerActions.toggleFullscreen);
      } else {
        await store.dispatch(PlayerActions.togglePaused);
      }
      break;
    case " ":
    case "MediaPlayPause":
      await store.dispatch(PlayerActions.togglePaused);
      break;
    case "MediaStop":
      await store.dispatch(PlayerActions.togglePaused);
      break;
    case "ArrowLeft":
    case "MediaRewind":
    case "Previous":
      await store.dispatch(PlayerActions.triggerPlaylistEntry, { prev: true, triggered: true });
      break;
    case "ArrowRight":
    case "MediaFastForward":
    case "Next":
      await store.dispatch(PlayerActions.triggerPlaylistEntry, { prev: false, triggered: true });
      break;
    case "AudioVolumeMute":
      await store.dispatch(PlayerActions.toggleMuted);
      break;
  }
});

window.addEventListener("message", async (event) => {
  if (event.data === "webplayer-reset") {
    await store.dispatch(PlayerActions.triggerPlaylistEntry, { prev: false, triggered: true, index: 0, prevIndex: -1 });
    await store.dispatch(PlayerActions.setPaused, true);
  } else if (event.data === "webplayer-play") {
    await store.dispatch(PlayerActions.setPaused, false);
  } else if (event.data === "webplayer-pause") {
    await store.dispatch(PlayerActions.setPaused, true);
  }
});

window.addEventListener("focus", () => {
  store.commit(PlayerMutations.setFocused, true);
});

window.addEventListener("blur", () => {
  store.commit(PlayerMutations.setFocused, false);
});

store.dispatch(PlayerActions.loadPlaylist);
