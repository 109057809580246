export const loadExternalSources = (): void => {
  const cssString = getExternalSource("css_styling");
  if (cssString !== "") {
    const style = document.createElement("style");
    style.innerHTML = cssString;
    document.head.insertAdjacentElement("beforeend", style);
  }
};

const getExternalSource = (gonCode: "css_styling") => {
  let scriptString = "";
  if (process.env.NODE_ENV !== "production") {
    // scriptString = require("!!raw-loader!../tests/data/testTracking.txt");
  } else if (typeof gon !== "undefined" && gon[gonCode] !== "") {
    scriptString = gon[gonCode];
  }
  return scriptString;
};
