import { MutationTree } from "vuex";
import { set } from "./helpers";
import { PlayerState, PlayerStateFields } from "./playerstate.types";

export enum PlayerMutations {
  setPlaylist = "setPlaylist",
  setFullscreen = "setFullscreen",
  setMuted = "setMuted",
  setPaused = "setPaused",
  setCurrentTime = "setCurrentTime",
  setLastTime = "setLastTime",
  setTimer = "setTimer",
  setPreviousIndex = "setPreviousIndex",
  setCurrentIndex = "setCurrentIndex",
  setNextIndex = "setNextIndex",
  setTriggered = "setTriggered",
  setFocused = "setFocused",
  setFullscreenElement = "setFullscreenElement",
}

export const mutations: MutationTree<PlayerState> = {
  [PlayerMutations.setPlaylist]: set(PlayerStateFields.playlist),
  [PlayerMutations.setPlaylist]: set(PlayerStateFields.playlist),
  [PlayerMutations.setFullscreen]: set(PlayerStateFields.fullscreen),
  [PlayerMutations.setCurrentTime]: set(PlayerStateFields.currentTime),
  [PlayerMutations.setLastTime]: set(PlayerStateFields.lastTime),
  [PlayerMutations.setPaused]: set(PlayerStateFields.paused),
  [PlayerMutations.setMuted]: set(PlayerStateFields.muted),
  [PlayerMutations.setPreviousIndex]: set(PlayerStateFields.previousIndex),
  [PlayerMutations.setCurrentIndex]: set(PlayerStateFields.currentIndex),
  [PlayerMutations.setNextIndex]: set(PlayerStateFields.nextIndex),
  [PlayerMutations.setTriggered]: set(PlayerStateFields.triggered),
  [PlayerMutations.setFocused]: set(PlayerStateFields.focused),
  [PlayerMutations.setFullscreenElement]: set(PlayerStateFields.fullscreenElement),
};
