import { GetterTree } from "vuex";
import { playlistService } from "../services/playlist.service";
import { urlParamService } from "../services/urlParam.service";
import { PlayerState } from "./playerstate.types";

export enum PlayerGetters {
  renderablePlaylist = "renderablePlaylist",
  audioPlaylist = "audioPlaylist",
  paramService = "paramService",
  overlayImage = "overlayImage",
  cssStyling = "cssStyling",
  trackingCodeBody = "trackingCodeBody",
  trackingCodeHead = "trackingCodeHead",
  fullscreen = "fullscreen",
  fullscreenElement = "fullscreenElement",
  currentTime = "currentTime",
  paused = "paused",
  totalTime = "totalTime",
  muted = "muted",
  currentIndex = "currentIndex",
  currentPlaylistItem = "currentPlaylistItem",
  previousIndex = "previousIndex",
  playlistsHash = "playlistsHash",
}

export const getters: GetterTree<PlayerState, unknown> = {
  [PlayerGetters.renderablePlaylist]: (state) => state.playlist,

  [PlayerGetters.audioPlaylist]: () => null,

  [PlayerGetters.paramService]: () => urlParamService,

  [PlayerGetters.overlayImage]: () => playlistService.getOverlay(),

  [PlayerGetters.cssStyling]: () => playlistService.getCssStyling(),

  [PlayerGetters.trackingCodeBody]: () => playlistService.getTrackingCodeBody(),

  [PlayerGetters.trackingCodeHead]: () => playlistService.getTrackingCodeHead(),

  [PlayerGetters.fullscreen]: (state) => state.fullscreen,

  [PlayerGetters.fullscreenElement]: (state) => state.fullscreenElement,

  [PlayerGetters.currentTime]: (state) => state.currentTime,

  [PlayerGetters.paused]: (state) => state.paused,

  [PlayerGetters.totalTime]: (state) =>
    state.playlist.map((item) => item.playlistEntry.duration * 1000).reduce((a, b) => a + b, 0),

  [PlayerGetters.muted]: (state) => state.muted,

  [PlayerGetters.currentIndex]: (state) => state.currentIndex,

  [PlayerGetters.currentPlaylistItem]: (state) => state.playlist[state.currentIndex],

  [PlayerGetters.previousIndex]: (state) => state.previousIndex,

  [PlayerGetters.playlistsHash]: () => playlistService.getPlaylistHash(),
};
