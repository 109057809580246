













import { MediaItem } from "@/interfaces/media-item";
import Component from "vue-class-component";
import { Ref, Watch } from "vue-property-decorator";
import BaseMedia from "./BaseMedia.vue";

@Component({})
export default class WebsiteMedia extends BaseMedia implements MediaItem {
  @Ref() mediaItem!: HTMLIFrameElement;
  url = "";

  created() {
    if (this.index < 5) {
      this.url = this.getUrl();
    } else {
      setTimeout(() => {
        this.url = this.getUrl();
      }, 150 * (this.index - 4));
    }
  }
  error() {
    console.log("error");
  }
  async startMedia() {
    await this.$nextTick();
    this.mediaItem?.classList.add("active");
  }

  getUrl() {
    if (this.mediaData.playlistEntry.widgetSettings) {
      if (this.mediaData.contentItem.url.includes("?")) {
        return `${this.mediaData.contentItem.url}&${this.mediaData.playlistEntry.widgetSettings.parameter}`;
      }
      return `${this.mediaData.contentItem.url}?${this.mediaData.playlistEntry.widgetSettings.parameter}`;
    }
    return `${this.mediaData.contentItem.url}`;
  }

  async stopMedia() {
    await this.$nextTick();
    this.mediaItem?.classList.remove("active");
  }

  @Watch("paused")
  async pausedWatch() {
    //nop
  }
}
