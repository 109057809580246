import { RendereablePlaylistEntry } from "../interfaces/playlist";

export enum PlayerStateFields {
  playlist = "playlist",
  fullscreen = "fullscreen",
  currentTime = "currentTime",
  lastTime = "lastTime",
  paused = "paused",
  muted = "muted",
  previousIndex = "previousIndex",
  currentIndex = "currentIndex",
  nextIndex = "nextIndex",
  triggered = "triggered",
  focused = "focused",
  fullscreenElement = "fullscreenElement",
}

export type PlayerState = {
  playlist: RendereablePlaylistEntry[];
  fullscreen: boolean;
  currentTime: number;
  lastTime: number;
  paused: boolean;
  muted: boolean;
  previousIndex: number;
  currentIndex: number;
  nextIndex: number;
  triggered: boolean;
  focused: boolean;
  fullscreenElement: HTMLElement;
};
