import { Breakpoint } from "vuetify/types/services/breakpoint";

class URLParamService {
  urlParam(name: string): string | null {
    const results = new RegExp("[?&]" + name + "=([^&#]*)").exec(document.location.href);
    if (results == null) {
      return null;
    } else {
      return decodeURI(results[1]) || null;
    }
  }

  //Default value: true
  isAudioMuted(): boolean {
    if (this.urlParam("mute-audio") === "false") {
      return false;
    } else {
      return true;
    }
  }

  //Default value: true
  useAutostart(): boolean {
    if (this.urlParam("autostart") === "false") {
      return false;
    } else {
      return true;
    }
  }

  mediaControlHideTimeout(breakpoint: Breakpoint): number {
    let factor = 1;
    const touchDevice = navigator.maxTouchPoints || "ontouchstart" in document.documentElement;

    switch (breakpoint.name) {
      case "xs":
      case "sm":
      case "md":
        if (touchDevice) {
          factor = 2;
        }
        break;
    }
    const timeout = this.urlParam("media-control-timeout") || 1500;
    return parseInt("" + timeout) * factor;
  }

  //Default value: false
  loopPlaylist(): boolean {
    if (this.urlParam("loop-playlist") === "true" || this.urlParam("loop") === "true") {
      return true;
    } else {
      return false;
    }
  }
  /// should use cdn and contentconvert?
  /// default: true
  useCdn(): boolean {
    if (this.urlParam("cdn") === "false") {
      return false;
    }
    return true;
  }

  get layout() {
    return this.urlParam("layout") || "default";
  }

  get looseFocusKeys(): string[] {
    return (this.urlParam("looseFocusKeys") || "ArrowUp").split(",");
  }
}

export const urlParamService = new URLParamService();
