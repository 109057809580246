export {};
declare global {
  export interface Array<T> {
    chunk(chunkSize: number): Array<Array<T>>;
  }
}

Array.prototype.chunk = function (chunkSize: number) {
  const R = [];
  for (let i = 0; i < this.length; i += chunkSize) R.push(this.slice(i, i + chunkSize));
  return R;
};
