import axios, { AxiosError } from "axios";
import moment from "moment";
import { TypedEmitter } from "tiny-typed-emitter";
import * as timer from "worker-timers";
import { Playlist, PlaylistEntry, PlaylistLayoutEvent, RendereablePlaylistEntry } from "../interfaces/playlist";
import { Safe4 } from "../interfaces/safe4";

export interface PlaylistServiceEvents {
  "playlist-updated": (event: PlaylistLayoutEvent) => void;
  "safe4-updated": () => void;
}

type RegisteredLayoutEvents = {
  playlistId: number;
  nextTriggerInMillis: number;
  startDateTime: moment.Moment;
  endDateTime?: moment.Moment;
};
class PlaylistService extends TypedEmitter<PlaylistServiceEvents> {
  public safe4Data: Safe4 | null = null;
  private lastEtag = "";
  private refreshLoopInitialized = -1;
  private registeredLayoutEvents = new Map<number, RegisteredLayoutEvents>();

  get isInititalized() {
    return this.safe4Data !== null;
  }
  constructor() {
    super();
  }

  async initialLoad() {
    if (this.isInititalized) {
      return;
    }
    await this.loadSafe4();
    // this.safe4Data? = process.env.NODE_ENV === "production" ? gon.safe4 : require("../../tests/data/safe4/web.json");
    this.registerLayoutEvents();
    this.reshuffleData();

    if (!this.safe4Data || !this.safe4Data?.playlistContainer || !this.safe4Data?.playlistContainer.playlists) {
      this.safe4Data = require("../static/empty_fallback.json");
    }

    this.renderPlaylist(this.getInitialPlaylist());
    this.startRefreshLoop();
  }

  getInitialPlaylist(initialMasterPlaylist?: boolean): Playlist {
    if (!initialMasterPlaylist) {
      const currentLayoutEvent = this.safe4Data?.layoutEventsContainer?.layoutEvents
        .filter((event) => event.end_date)
        .find((event) =>
          moment().isBetween(
            moment(`${event.start_date} ${event.start_time}`),
            moment(`${event.end_date} ${event.end_time}`)
          )
        );

      const layout = this.safe4Data?.layoutContainer.layouts.find(
        (layout) => layout.id === currentLayoutEvent?.layoutId
      );
      if (currentLayoutEvent) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        return this.safe4Data?.playlistContainer.playlists.find((p) => p.id === layout?.zones[0].playlistId)!;
      } else {
        if (this.safe4Data?.playerContainer.startScreenLayoutId) {
          const playlistId = this.safe4Data?.layoutContainer.layouts.find(
            (l) => l.id === this.safe4Data?.playerContainer.startScreenLayoutId
          )?.zones[0].playlistId;
          return this.getPlaylistById(playlistId!);
        }
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    return this.safe4Data?.playlistContainer.playlists.find((playlist) => playlist.type === "master_playlist")!;
  }

  getPlaylistById(playlistId: number) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    return this.safe4Data?.playlistContainer.playlists.find((playlist) => playlist.id === playlistId)!;
  }

  private registerLayoutEvents() {
    const events: RegisteredLayoutEvents[] | undefined = this.safe4Data?.layoutEventsContainer?.layoutEvents.flatMap(
      (event) => {
        let startDateTime;
        const initialStartDateTime = moment(`${event.start_date} ${event.start_time}`);
        if (event.repetition && event.eventType === "forcedStart") {
          const repetitionTime = moment(event.start_time, "HH:mm");
          startDateTime = moment().minutes(repetitionTime.minutes()).seconds(0);
          switch (event.repetition_type) {
            case "hourly":
              startDateTime = startDateTime.add(repetitionTime.minutes() <= moment().minutes() ? 1 : 0, "hours");
              break;
            case "daily":
              startDateTime = startDateTime.hours(repetitionTime.hours());
              startDateTime = startDateTime.add(repetitionTime.hours() <= moment().hours() ? 1 : 0, "day");
              break;
            case "weekly":
              startDateTime = startDateTime.hours(repetitionTime.hours());
              startDateTime = startDateTime.add(repetitionTime.hours() <= moment().hours() ? 1 : 0, "day");
              startDateTime = startDateTime.weekday(initialStartDateTime.weekday());
              startDateTime = startDateTime.add(initialStartDateTime.weekday() <= moment().weekday() ? 1 : 0, "week");
              break;
          }
        } else {
          startDateTime = initialStartDateTime;
        }
        const endDateTime = event.end_date ? moment(`${event.end_date} ${event.end_time}`) : undefined;
        const nextTriggerInMillis = startDateTime.diff(moment());
        const layout = this.safe4Data?.layoutContainer.layouts.find((layout) => layout.id === event.layoutId);
        const zone = layout?.zones[0];

        return {
          playlistId: zone!.playlistId,
          nextTriggerInMillis,
          startDateTime,
          endDateTime,
        };
      }
    );

    if (events) {
      for (const event of events) {
        if (event.nextTriggerInMillis > 0) {
          if (!this.registeredLayoutEvents.has(event.playlistId)) {
            this.registeredLayoutEvents.set(event.playlistId, event);
            //console.log("register", event);
            timer.setTimeout(() => {
              this.emit("playlist-updated", event);
              this.registeredLayoutEvents.delete(event.playlistId);
              this.registerLayoutEvents();
            }, event.nextTriggerInMillis);
          }
        }
        if (event.endDateTime && event.endDateTime?.isAfter(moment())) {
          const initialMasterPlaylist = this.getInitialPlaylist(true);
          if (!this.registeredLayoutEvents.has(initialMasterPlaylist.id)) {
            const endEvent: RegisteredLayoutEvents = {
              playlistId: initialMasterPlaylist.id,
              startDateTime: event.endDateTime,
              nextTriggerInMillis: Math.abs(moment().diff(event.endDateTime)),
            };
            this.registeredLayoutEvents.set(endEvent.playlistId, endEvent);
            //console.log("trigger endevent", endEvent);
            timer.setTimeout(() => {
              this.emit("playlist-updated", endEvent);
              this.registeredLayoutEvents.delete(event.playlistId);
            }, endEvent.nextTriggerInMillis);
          }
        }
      }
    }
  }

  renderPlaylist(playlist: Playlist): RendereablePlaylistEntry[] {
    let playlistEntries: RendereablePlaylistEntry[] = [];
    if (playlist) {
      for (const [index, playlistEntry] of playlist.entries.entries()) {
        const data = this.getPlaylistElement(
          playlistEntry,
          playlist,
          index + 1 < playlist.entries.length ? playlist.entries[index + 1] : undefined
        );

        if (data) {
          playlistEntries = playlistEntries.concat(data);
        }
      }
      return playlistEntries;
    }
    return [];
  }

  getPlaylistElement(
    currentEntry: PlaylistEntry,
    playlist: Playlist,
    nextEntry?: PlaylistEntry
  ): RendereablePlaylistEntry[] {
    let contentItem;
    if (currentEntry.type === "content") {
      if (this.isActive(currentEntry)) {
        contentItem = this.safe4Data?.contentContainer.content.find((content) => content.id === currentEntry.contentId);
        if (!contentItem || contentItem?.url.length === 0) {
          console.warn("Skipping content item: ", contentItem);
          return [];
        }
        return [
          {
            contentItem,
            playlistEntry: currentEntry,
            transition: nextEntry && nextEntry.type === "transition" ? nextEntry.transition : playlist.transition,
          },
        ];
      }
      return [];
    } else if (currentEntry.type === "playlist") {
      const playlist = this.safe4Data?.playlistContainer.playlists.find(
        (playlist) => playlist.id === currentEntry.playlistId
      );
      if (!playlist) {
        return [];
      }
      if (currentEntry.randomPlay) {
        if (playlist.shuffledEntries.length > 0) {
          const randomPlayCount = currentEntry.randomPlayCount || 1;
          //console.log('randomplay count: ', randomPlayCount);
          const channelItems = playlist?.shuffledEntries
            .slice(0, randomPlayCount)
            .map((item) => {
              //console.log('item', item);
              const elem = this.getPlaylistElement(item, playlist);
              //console.log('elems: ', elem);
              return elem;
            })
            .reduce((a, b) => {
              const concat = a.concat(b);
              return concat;
            });
          //console.log('entries: ', playlist.shuffledEntries);
          //console.log('result items: ', channelItems);
          return channelItems;
        }
      } else if (playlist?.type === "internal_playlist" || playlist?.type === "channel") {
        return this.renderPlaylist(playlist);
      }
      return [];
    }
    return [];
  }

  reshuffleData(): void {
    if (
      this.safe4Data?.playlistContainer &&
      this.safe4Data?.playlistContainer.playlists &&
      this.safe4Data?.playlistContainer.playlists.length
    ) {
      for (const playlist of this.safe4Data?.playlistContainer.playlists) {
        playlist.shuffledEntries = [...playlist.entries].filter(this.isActive).sort(() => 0.5 - Math.random());
      }
    }
  }

  isActive(playlistEntry: PlaylistEntry): boolean {
    if (!playlistEntry.scheduleInformation) {
      return true;
    }
    const scheduleInformation = playlistEntry.scheduleInformation;

    const now = moment();
    const start = moment(scheduleInformation.start_date);
    const end = moment(scheduleInformation.end_date);
    const startTime = moment(scheduleInformation.start_time, "HH:mm");
    const endTime = moment(scheduleInformation.end_time, "HH:mm");

    const weekday = now.weekday();
    const allowedWeekdays: number[] = [];
    if (scheduleInformation.monday) {
      allowedWeekdays.push(1);
    }
    if (scheduleInformation.tuesday) {
      allowedWeekdays.push(2);
    }
    if (scheduleInformation.wednesday) {
      allowedWeekdays.push(3);
    }
    if (scheduleInformation.thursday) {
      allowedWeekdays.push(4);
    }
    if (scheduleInformation.friday) {
      allowedWeekdays.push(5);
    }
    if (scheduleInformation.saturday) {
      allowedWeekdays.push(6);
    }
    if (scheduleInformation.sunday) {
      allowedWeekdays.push(0);
    }

    //verify the case if start and end are equal and its today
    //we can handle it by verify if start is today!
    const today = moment().startOf("day");
    const date = now.isBetween(start, end) || start.valueOf() - today.valueOf() == 0;
    const time = now.isBetween(startTime, endTime);
    const day = allowedWeekdays.findIndex((i) => i === weekday) !== -1;
    return date && time && day;
  }

  getOverlay() {
    if (
      !this.safe4Data?.playerContainer.modelSpecific ||
      !this.safe4Data?.playerContainer.modelSpecific.overlayImage ||
      this.safe4Data?.playerContainer.modelSpecific.overlayImage.length === 0
    ) {
      return null;
    }
    return this.safe4Data?.playerContainer.modelSpecific.overlayImage[0];
  }

  getCssStyling() {
    if (
      !this.safe4Data?.playerContainer.modelSpecific ||
      !this.safe4Data?.playerContainer.modelSpecific.cssStyling ||
      this.safe4Data?.playerContainer.modelSpecific.cssStyling.length === 0
    ) {
      return null;
    }
    return this.safe4Data?.playerContainer.modelSpecific.cssStyling;
  }

  getTrackingCodeBody() {
    if (
      !this.safe4Data?.playerContainer.modelSpecific ||
      !this.safe4Data?.playerContainer.modelSpecific.trackingCodeBody ||
      this.safe4Data?.playerContainer.modelSpecific.trackingCodeBody.length === 0
    ) {
      return null;
    }
    return this.safe4Data?.playerContainer.modelSpecific.trackingCodeBody;
  }

  getTrackingCodeHead() {
    if (
      !this.safe4Data?.playerContainer.modelSpecific ||
      !this.safe4Data?.playerContainer.modelSpecific.trackingCodeHead ||
      this.safe4Data?.playerContainer.modelSpecific.trackingCodeHead.length === 0
    ) {
      return null;
    }
    return this.safe4Data?.playerContainer.modelSpecific.trackingCodeHead;
  }

  startRefreshLoop() {
    if (this.safe4Data?.playerContainer.refreshCommunicationFile && this.refreshLoopInitialized === -1) {
      this.refreshLoopInitialized = timer.setInterval(async () => {
        await this.loadSafe4();
      }, 10000);
    }
  }

  async loadSafe4() {
    const paths = location.pathname.split("/");
    const url = `${process.env.VUE_APP_API_BASE_URL}/v1/safe4/${
      paths[paths.length - 1] || "beb28c88-e4cd-43b4-89eb-5c338e8feb28"
    }`;

    let loadNew = true;
    if (this.lastEtag !== "") {
      try {
        await axios.head(url, {
          headers: {
            "If-None-Match": this.lastEtag,
          },
        });
        loadNew = true;
      } catch (err) {
        loadNew = (err as AxiosError)?.response?.status !== 304;
      }
    }

    if (loadNew) {
      const data = await axios.get(url);
      this.lastEtag = data.headers.etag;
      const newSafe4: Safe4 = data.data;
      // console.log(
      //   "playlist changed",
      //   newSafe4.playlistContainer.playlistsHash !== this.safe4Data?.playlistContainer.playlistsHash
      // );

      console.log(data);
      if (newSafe4.playlistContainer.playlistsHash !== this.safe4Data?.playlistContainer.playlistsHash) {
        this.safe4Data = newSafe4;
        this.reshuffleData();
        this.emit("safe4-updated");
      }
    }
  }

  getPlaylistHash() {
    return this.safe4Data?.playlistContainer.playlistsHash;
  }
}

export const playlistService = new PlaylistService();
