




















































































import Vue from "vue";
// import $ from "jquery";
// import { eventBus, events } from "./eventBus";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { RendereablePlaylistEntry } from "../interfaces/playlist";
import { PlayerActions } from "../store/store.actions";
import { PlayerGetters } from "../store/store.getters";

@Component({})
export default class PlaylistSelection extends Vue {
  closed = false;
  @Action(PlayerActions.setCurrentIndex) setCurrentIndex!: (index: number) => void;
  @Action(PlayerActions.setPaused) setPaused!: (paused: boolean) => void;
  @Getter(PlayerGetters.renderablePlaylist) playlist!: RendereablePlaylistEntry[];
  @Getter(PlayerGetters.paused) paused!: boolean;
  @Getter(PlayerGetters.currentIndex) currentIndex!: number;
  @Action(PlayerActions.setTriggered) setTriggered!: (triggered: boolean) => void;
  @Getter(PlayerGetters.playlistsHash) playlistsHash!: string;

  close() {
    this.closed = true;
  }

  triggerPlaylistItem(item: RendereablePlaylistEntry) {
    const playlistIndex = this.playlist.findIndex(
      (entry: RendereablePlaylistEntry) => entry.contentItem.url === item.contentItem.url
    );
    this.setTriggered(true);
    this.setCurrentIndex(playlistIndex);
  }

  getUrl(item: RendereablePlaylistEntry): string {
    if (item.contentItem.thumbnail_url) {
      return item.contentItem.thumbnail_url;
    }
    return item.contentItem.url;
  }

  breakpointToChunkSize(): number {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return 1;
      case "sm":
        return 1;
      case "md":
        return 2;
      case "lg":
        return 3;
      case "xl":
        return 4;
    }
  }

  selectionStyle(chunkIndex: number, itemIndex?: number) {
    return this.breakpointToChunkSize() > 1 &&
      chunkIndex === this.carouselPage &&
      itemIndex === this.currentIndex % this.breakpointToChunkSize()
      ? {
          boxShadow: "rgba(139, 195, 74,0.9) 0 0 0 3px",
          borderRadius: "1px",
          backgroundColor: "rgba(139, 195, 74,0.9)",
        }
      : {};
  }

  get chunkedPlaylist(): RendereablePlaylistEntry[][] {
    if (this.playlist.length > 0) {
      return this.playlist
        .map((val, index) => {
          return { ...val, index };
        })
        .chunk(this.breakpointToChunkSize());
    }
    return [];
  }

  get carouselPage() {
    return Math.floor(this.currentIndex / this.breakpointToChunkSize());
  }
  get rippleOptions() {
    return this.breakpointToChunkSize() > 1 ? { center: true, class: "primary--text" } : false;
  }
  get dialogWidth() {
    const portrait = window.matchMedia("(orientation: portrait)").matches;
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
      case "sm":
        return portrait ? "100%" : "65%";
    }
    return "80%";
  }
  get carouselHeight() {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return 184;
      case "sm":
        return 324;
      case "md":
        return 230;
      case "lg":
        return 300;
      case "xl":
        return 400;
      default:
        return 400;
    }
  }

  @Watch("paused", { deep: true })
  pausedWatcher(newVal: boolean, oldVal: boolean) {
    this.closed = oldVal;
  }
}
