import Vue from "vue";
import App from "./App.vue";
import { store } from "./store";
import vuetify from "./plugins/vuetify";
import objectFitImages from "object-fit-images";
import { loadExternalSources } from "./services/stylingExtension";
import "@/utils/array";

loadExternalSources();

Vue.config.productionTip = false;

new Vue({
  store,
  vuetify,
  render: (h) => h(App),
  updated() {
    this.$nextTick(() => {
      objectFitImages();
    });
  },
}).$mount("#app");
