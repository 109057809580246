





import Vue from "vue";
import Component from "vue-class-component";
import { urlParamService } from "../services/urlParam.service";
import DefaultWebplayerLayout from "./layouts/DefaultWebplayerLayout.vue";
import IptvWebplayerLayout from "./layouts/IPTVWebplayerLayout.vue";

@Component({
  components: {
    DefaultWebplayerLayout,
    IptvWebplayerLayout,
  },
})
export default class WebplayerLayout extends Vue {
  get layout() {
    return urlParamService.layout;
  }
}
