<template>
  <div>
    <audio
      autoplay
      v-if="audioPlaylist && audioPlaylist.length > 0 && currentTrack"
      @ended="nextTrack"
      ref="audioTrack"
      :src="currentTrack.contentItem.url"
      :key="currentTrack.contentItem.id"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      currentTrackNumber: 0,
    };
  },
  computed: {
    ...mapGetters(["audioPlaylist"]),
    currentTrack() {
      if (this.currentTrackNumber in this.audioPlaylist) {
        return this.audioPlaylist[this.currentTrackNumber];
      }
      return null;
    },
  },

  methods: {
    async nextTrack() {
      if (this.currentTrackNumber + 1 >= this.audioPlaylist.length) {
        this.currentTrackNumber = 0;
      } else {
        this.currentTrackNumber++;
      }
      await this.$refs.audioTrack.play();
    },
  },
};
</script>

<style></style>
