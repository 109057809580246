















































































































































































import PlaylistSelection from "@/components/PlaylistSelection.vue";
import { gsap, TimelineLite } from "gsap";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import Component from "vue-class-component";
import { ProvideReactive, Watch } from "vue-property-decorator";
import SsrCarousel from "vue-ssr-carousel";
import "vue-ssr-carousel/index.css";
import { Action, Getter, State } from "vuex-class";
import { MediaItemWithRef } from "../../interfaces/media-item";
import { urlParamService } from "../../services/urlParam.service";
import { PlayerStateFields } from "../../store/playerstate.types";
import { PlayerActions } from "../../store/store.actions";
import { PlayerGetters } from "../../store/store.getters";
import AudioPlayer from "../AudioPlayer.vue";
import ImageMedia from "../media-items/ImageMedia.vue";
import VideoMedia from "../media-items/VideoMedia.vue";
import VideoStreamMedia from "../media-items/VideoStreamMedia.vue";
import WebsiteMedia from "../media-items/WebsiteMedia.vue";
import MediaControls from "../MediaControls.vue";
import DefaultWebplayerLayout from "./DefaultWebplayerLayout.vue";
gsap.registerPlugin(CSSRulePlugin);
gsap.registerPlugin(TimelineLite);

@Component({
  components: {
    AudioPlayer,
    ImageMedia,
    MediaControls,
    PlaylistSelection,
    VideoStreamMedia,
    VideoMedia,
    WebsiteMedia,
    SsrCarousel,
  },
})
export default class IPTVWebplayerLayout extends DefaultWebplayerLayout {
  @ProvideReactive() calculatedHeight = 0;

  @Action(PlayerActions.setFullscreenElement) setFullscreenElement!: (element: HTMLElement) => void;
  @Getter(PlayerGetters.fullscreen) fullscreen!: boolean;
  @State(PlayerStateFields.focused) focused!: boolean;

  $refs!: {
    carousel: any;
    [key: number]: any;
    [key: string]: any;
  };

  animating = false;
  started = false;

  async mounted() {
    this.calculatedHeight = window.innerHeight;

    this.setFullscreenElement(this.playerRoot);
  }

  onResize() {
    this.calculatedHeight = window.innerHeight;
  }

  @Watch("currentIndex", { immediate: true })
  async watchCurrentIndex() {
    if (this.currentIndex != this.previousIndex) {
      await this.$nextTick();
      await this._startSlide();
      //console.log("IPTV: index");
    }
  }
  @Watch("playlist", { deep: true })
  async watchPlaylist() {
    await this.$forceUpdate();
    await this.$nextTick();
    await this._startSlide();
  }

  @Watch("fullscreen")
  async watchFullscreen() {
    await this.$nextTick();
    if (this.fullscreen) {
      await this.startSlide();
    }
  }

  getElement(index: number): MediaItemWithRef | null {
    console.log(index, this.$refs);
    if (index in this.$refs) {
      const elementRef = this.$refs[index] as any;
      if (elementRef && length in elementRef) {
        return elementRef[0];
      }
      return elementRef;
    }
    return null;
  }

  playPause(dbl: boolean) {
    const touchDevice = navigator.maxTouchPoints || "ontouchstart" in document.documentElement;
    if (!touchDevice || dbl) {
      this.setPaused(!this.paused);
    }
  }

  onAnimationStart({ index }: { index: number }) {
    this.animating = true;
    console.log("onAnimationStart", index);
  }

  async onAnimationEnd({ index }: { index: number }) {
    this.animating = false;
    await this.$nextTick();
    console.log("onAnimationEnd", index);
    await this.startMedia();
  }

  async startMedia() {
    if (this.previousIndex !== -1 && this.playlist[this.previousIndex].contentItem.type !== "video") {
      console.log("LAYOUT: stopMedia()");
      await this.getElement(this.previousIndex)?.stopMedia();
    }
    console.log("LAYOUT: startMedia()");
    await this.getElement(this.currentIndex)!.startMedia();
  }

  async _startSlide() {
    if (this.fullscreen) {
      this.startSlide();
    } else {
      this.$refs.carousel.goto(this.currentIndex);
      if (!this.started) {
        this.started = true;
        await this.startMedia();
      }
    }
  }

  getActiveClass(index: number) {
    if (index === this.currentIndex) {
      return "active";
    } else if (index === this.currentIndex + 1) {
      return "next";
    } else if (index === this.currentIndex - 1) {
      return "prev";
    }
    return "";
  }

  getVideoActiveClass() {
    return { "active-video-element": true, focused: this.focused };
  }

  get closeDelay() {
    console.log(urlParamService.mediaControlHideTimeout(this.$vuetify.breakpoint));
    return urlParamService.mediaControlHideTimeout(this.$vuetify.breakpoint);
  }

  get activeContentType(): string {
    return this.playlist[this.currentIndex]?.contentItem.type || "";
  }
}
