import moment from "moment";
import * as timers from "worker-timers";
import { PlaylistEntry } from "../interfaces/playlist";

type RunningTimer = {
  playlistEntry: PlaylistEntry;
  startTime: moment.Moment;
  remainingDuration: number;
  timerId?: number;
};

class PlaylistTimer {
  private runningTimerRegistry = new Map<number, RunningTimer>();

  public startTimer(playlistEntry: PlaylistEntry, callback: (...arg: any) => any) {
    const timer = this.runningTimerRegistry.get(playlistEntry.id);

    if (timer) {
      this.restartTimer(timer, callback);
    } else {
      this.startNewTimer(playlistEntry, callback);
    }
  }

  public clearAll() {
    for (const timer of this.runningTimerRegistry.values()) {
      if (timer.timerId) {
        timers.clearTimeout(timer.timerId);
      }
    }
    this.runningTimerRegistry.clear();
  }

  public pauseTimer(playlistEntry: PlaylistEntry) {
    const timer = this.runningTimerRegistry.get(playlistEntry.id);

    if (timer) {
      const remainingDuration = timer.remainingDuration - Math.abs(timer.startTime.diff(moment(), "milliseconds"));
      timers.clearTimeout(timer.timerId!);
      timer.timerId = undefined;
      timer.remainingDuration = remainingDuration;
      this.runningTimerRegistry.set(playlistEntry.id, timer);
    }
  }

  private restartTimer(timer: RunningTimer, callback: (...arg: any) => any) {
    const timerId = timers.setTimeout(() => {
      this.runningTimerRegistry.delete(timer.playlistEntry.id);
      callback();
    }, timer.remainingDuration);

    timer.timerId = timerId;
    timer.startTime = moment();
    this.runningTimerRegistry.set(timer.playlistEntry.id, timer);
  }

  private startNewTimer(playlistEntry: PlaylistEntry, callback: (...arg: any) => any) {
    const timerId = timers.setTimeout(() => {
      this.runningTimerRegistry.delete(playlistEntry.id);
      callback();
    }, playlistEntry.duration * 1000);

    const timer: RunningTimer = {
      timerId,
      playlistEntry: playlistEntry,
      remainingDuration: playlistEntry.duration * 1000,
      startTime: moment(),
    };
    this.runningTimerRegistry.set(playlistEntry.id, timer);
  }

  public get timers() {
    return [...this.runningTimerRegistry.values()];
  }
}

export const playlistTimer = new PlaylistTimer();
