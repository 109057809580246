
import { RendereablePlaylistEntry } from "@/interfaces/playlist";
import { PlayerGetters } from "@/store/store.getters";
import Vue from "vue";
import { InjectReactive, Prop } from "vue-property-decorator";
import { Getter, State } from "vuex-class";
import { urlParamService } from "../../services/urlParam.service";
import { PlayerStateFields } from "../../store/playerstate.types";

export default abstract class BaseMedia extends Vue {
  @Prop({ required: true }) mediaData!: RendereablePlaylistEntry;
  @Prop({ required: true, default: 0 }) index!: number;
  @Prop({ required: false, default: false }) alwaysVisible!: boolean;
  @Getter(PlayerGetters.paused) paused!: boolean;
  @Getter(PlayerGetters.currentIndex) currentIndex!: number;
  @Getter(PlayerGetters.fullscreen) fullscreen!: boolean;
  @State(PlayerStateFields.focused) focused!: boolean;
  isError = false;
  @InjectReactive() calculatedHeight!: number;

  get active(): boolean {
    return this.index === this.currentIndex;
  }
  get layout() {
    return urlParamService.layout;
  }

  get classes() {
    return {
      "sp--player--item": true,
      "default-layout": this.layout === "default",
      "always-visible": this.alwaysVisible,
      fullscreen: this.fullscreen,
      focused: this.focused,
    };
  }
}
