








































































import PlaylistSelection from "@/components/PlaylistSelection.vue";
// import { eventBus, events } from "./eventBus";
import { gsap, TimelineLite } from "gsap";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import Vue from "vue";
import Component from "vue-class-component";
import { ProvideReactive, Ref, Watch } from "vue-property-decorator";
import { Action, Getter, State } from "vuex-class";
import { MediaItemWithRef } from "../../interfaces/media-item";
import { OverlayImage } from "../../interfaces/player";
import { RendereablePlaylistEntry, Transition } from "../../interfaces/playlist";
import { urlParamService } from "../../services/urlParam.service";
import { PlayerStateFields } from "../../store/playerstate.types";
import { PlayerActions } from "../../store/store.actions";
import { PlayerGetters } from "../../store/store.getters";
import AudioPlayer from "../AudioPlayer.vue";
//import the necessary media types
import ImageMedia from "../media-items/ImageMedia.vue";
import VideoMedia from "../media-items/VideoMedia.vue";
import VideoStreamMedia from "../media-items/VideoStreamMedia.vue";
import WebsiteMedia from "../media-items/WebsiteMedia.vue";
import MediaControls from "../MediaControls.vue";
gsap.registerPlugin(CSSRulePlugin);
gsap.registerPlugin(TimelineLite);

@Component({
  components: {
    AudioPlayer,
    ImageMedia,
    MediaControls,
    PlaylistSelection,
    VideoStreamMedia,
    VideoMedia,
    WebsiteMedia,
  },
})
export default class DefaultWebplayerLayout extends Vue {
  @ProvideReactive() calculatedHeight = 0;
  @Action(PlayerActions.setPaused) setPaused!: (paused: boolean) => void;
  @Action(PlayerActions.toggleMuted) toggleMuted!: () => void;
  @Action(PlayerActions.triggerPlaylistEntry) triggerPlaylistEntry!: ({
    prev,
    triggered,
  }: {
    prev: boolean;
    triggered: boolean;
  }) => Promise<void>;

  @Getter(PlayerGetters.renderablePlaylist) playlist!: RendereablePlaylistEntry[];
  @Getter(PlayerGetters.overlayImage) overlayImage!: OverlayImage;
  @Getter(PlayerGetters.paused) paused!: boolean;
  @Getter(PlayerGetters.muted) muted!: boolean;
  @Getter(PlayerGetters.currentIndex) currentIndex!: number;
  @Getter(PlayerGetters.previousIndex) previousIndex!: number;
  @Getter(PlayerGetters.playlistsHash) playlistsHash!: string;
  @Getter(PlayerGetters.audioPlaylist) audioPlaylist!: unknown;

  @State(PlayerStateFields.triggered) triggered!: boolean;

  @Ref() playerRoot!: HTMLDivElement;

  animating = false;

  async mounted() {
    console.log("default");
    await this.triggerPlaylistEntry({ prev: false, triggered: true });
    this.calculatedHeight = window.innerHeight;
  }

  onResize() {
    this.calculatedHeight = window.innerHeight;
  }

  @Watch("currentIndex")
  async watchCurrentIndex() {
    if (this.currentIndex != this.previousIndex) {
      await this.$nextTick();
      await this.startSlide();
      console.log("DEFAULT: watch currentindex?");
    }
  }

  @Watch("playlist", { deep: true })
  async watchPlaylist() {
    console.log("watch pl?");

    await this.$forceUpdate();
    await this.$nextTick();
    for (let i = 0; i < this.playlist.length; ++i) {
      const mediaItem = this.getElement(i)?.$refs.mediaItem;
      if (mediaItem) {
        mediaItem.style.opacity = "0";
      }
    }
    await this.startSlide();
  }

  get closeDelay() {
    return urlParamService.mediaControlHideTimeout(this.$vuetify.breakpoint);
  }

  getElement(index: number): MediaItemWithRef | null {
    if (index in this.$refs) {
      return (this.$refs[index] as any)[0];
    }
    return null;
  }

  playPause(dbl: boolean) {
    const touchDevice = navigator.maxTouchPoints || "ontouchstart" in document.documentElement;
    if (!touchDevice || dbl) {
      this.setPaused(!this.paused);
    }
  }

  async startSlide() {
    console.log("DEFAULT: startSlide");
    const transition: Transition | undefined = this.playlist[this.previousIndex]?.transition;

    if (this.previousIndex !== -1) {
      await this.getElement(this.previousIndex)?.stopMedia();
    }
    await this.getElement(this.currentIndex)!.startMedia();

    const currentElementMedia = this.getElement(this.currentIndex)?.$refs.mediaItem;
    const previousElementMedia = this.getElement(this.previousIndex)?.$refs.mediaItem;

    if (!document.hidden && transition && !this.triggered) {
      this.animating = true;

      if (transition.transitionType === "CrossFade") {
        this.crossFade(previousElementMedia, transition, currentElementMedia);
      } else if (transition.transitionType === "ColorCrossFade") {
        this.colorCrossFade(previousElementMedia, transition, currentElementMedia);
      }
    } else {
      if (previousElementMedia) {
        previousElementMedia.style.opacity = "0";
      }
      if (currentElementMedia) {
        console.log("set opacity");
        currentElementMedia.style.opacity = "1";
      }
    }
  }

  private colorCrossFade(
    previousElementMedia: HTMLImageElement | HTMLVideoElement | undefined,
    transition: Transition,
    currentElementMedia: HTMLImageElement | HTMLVideoElement | undefined
  ) {
    const timeline = gsap.timeline();
    if (previousElementMedia) {
      timeline
        .to(previousElementMedia, {
          duration: transition.fadeOutDuration,
          css: {
            opacity: 0,
          },
        })
        .to(
          this.playerRoot,
          {
            duration: transition.fadeOutDuration,
            css: {
              backgroundColor: `rgb(${transition.transitionRed}, ${transition.transitionGreen}, ${transition.transitionBlue})`,
            },
          },
          `-=${transition.fadeOutDuration}`
        )
        .to(
          currentElementMedia!,
          { duration: transition.fadeInDuration, css: { opacity: 1 } },
          `+=${transition.colorDuration || 0}`
        )
        .to(
          this.playerRoot,
          {
            duration: transition.fadeInDuration,
            css: { backgroundColor: "rgb(255, 255, 255)" },
          },
          `-=${transition.fadeInDuration}`
        );

      timeline.restart();
    }
  }

  private crossFade(
    previousElementMedia: HTMLImageElement | HTMLVideoElement | undefined,
    transition: Transition,
    currentElementMedia: HTMLImageElement | HTMLVideoElement | undefined
  ) {
    if (previousElementMedia) {
      gsap.to(previousElementMedia, {
        duration: transition.totalDuration,
        css: { opacity: 0 },
      });
    }

    gsap.to(currentElementMedia!, {
      duration: transition.totalDuration,
      css: { opacity: 1 },
    });
  }
}
