import Vue from "vue";
import { ActionTree } from "vuex";
import { RendereablePlaylistEntry } from "../interfaces/playlist";
import { playlistService } from "../services/playlist.service";
import { playlistTimer } from "../services/timer";
import { urlParamService } from "../services/urlParam.service";
import { PlayerState, PlayerStateFields } from "./playerstate.types";
import { PlayerGetters } from "./store.getters";
import { PlayerMutations } from "./store.mutations";

export enum PlayerActions {
  loadPlaylist = "loadPlaylist",
  updateCurrentTime = "updateCurrentTime",
  setPaused = "setPaused",
  setStartTimeForIndex = "setStartTimeForIndex",
  reshuffleAndLoad = "reshuffleAndLoad",
  toggleFullscreen = "toggleFullscreen",
  toggleMuted = "toggleMuted",
  togglePaused = "togglePaused",
  requestFullscreen = "requestFullscreen",
  exitFullscreen = "exitFullscreen",
  resetTimer = "resetTimer",
  triggerPlaylistEntry = "triggerPlaylistEntry",
  setCurrentIndex = "setCurrentIndex",
  setTriggered = "setTriggered",
  setFullscreenElement = "setFullscreenElement",
}

export const actions: ActionTree<PlayerState, PlayerState> = {
  async [PlayerActions.loadPlaylist]({ commit }, payload: { playlistId?: number; initialMasterPlaylist?: true }) {
    await playlistService.initialLoad();

    if (payload?.playlistId) {
      commit(
        PlayerMutations.setPlaylist,
        playlistService.renderPlaylist(playlistService.getPlaylistById(payload.playlistId))
      );
    } else {
      commit(
        PlayerMutations.setPlaylist,
        playlistService.renderPlaylist(playlistService.getInitialPlaylist(payload?.initialMasterPlaylist))
      );
    }
  },

  [PlayerActions.updateCurrentTime]({ commit, state }) {
    const curr = new Date().getTime();
    if (!state.lastTime) {
      commit(PlayerMutations.setLastTime, curr);
    }
    if (!state.paused) {
      commit(PlayerMutations.setCurrentTime, state.currentTime + curr - state.lastTime);
    }

    commit(PlayerMutations.setLastTime, curr);
  },

  [PlayerActions.setPaused]({ commit, dispatch, getters }, paused: boolean) {
    if (paused) {
      playlistTimer.pauseTimer((getters[PlayerGetters.currentPlaylistItem] as RendereablePlaylistEntry).playlistEntry);
    } else {
      playlistTimer.startTimer(
        (getters[PlayerGetters.currentPlaylistItem] as RendereablePlaylistEntry).playlistEntry,
        () => {
          dispatch(PlayerActions.triggerPlaylistEntry, { prev: false, triggered: false });
        }
      );
    }
    commit(PlayerMutations.setPaused, paused);
  },

  [PlayerActions.setStartTimeForIndex]({ commit, state }, index) {
    let currentTime = 0;
    for (let i = index - 1; i >= 0; --i) {
      currentTime += state.playlist[i].playlistEntry.duration * 1000;
    }
    commit(PlayerMutations.setCurrentTime, currentTime);
  },

  async [PlayerActions.reshuffleAndLoad]({ dispatch }) {
    playlistService.reshuffleData();
    await dispatch(PlayerActions.loadPlaylist);

    dispatch(PlayerActions.resetTimer);
  },

  [PlayerActions.resetTimer]({ commit }) {
    commit(PlayerMutations.setCurrentTime, 0);
    commit(PlayerMutations.setLastTime, new Date().getTime());
  },

  [PlayerActions.toggleFullscreen]({ commit, dispatch, state }) {
    if (state.fullscreen) {
      commit(PlayerMutations.setFullscreen, false);
      dispatch(PlayerActions.exitFullscreen);
    } else {
      commit(PlayerMutations.setFullscreen, true);
      dispatch(PlayerActions.requestFullscreen);
    }
  },

  [PlayerActions.toggleMuted]({ commit, state }) {
    commit(PlayerMutations.setMuted, !state.muted);
  },

  [PlayerActions.togglePaused]({ dispatch, state }) {
    dispatch(PlayerActions.setPaused, !state.paused);
  },

  [PlayerActions.requestFullscreen]({ getters }) {
    // go full-screen
    const element = <HTMLElement>getters[PlayerStateFields.fullscreenElement];
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if ((element as any).webkitRequestFullscreen) {
      (element as any).webkitRequestFullscreen();
    } else if ((element as any).mozRequestFullScreen) {
      (element as any).mozRequestFullScreen();
    } else if ((element as any).msRequestFullscreen) {
      (element as any).msRequestFullscreen();
    }
  },

  [PlayerActions.exitFullscreen]() {
    // exit full-screen
    const doc = <any>document;

    if (doc.exitFullscreen) {
      doc.exitFullscreen();
    } else if (doc.webkitExitFullscreen) {
      doc.webkitExitFullscreen();
    } else if (doc.mozCancelFullScreen) {
      doc.mozCancelFullScreen();
    } else if (doc.msExitFullscreen) {
      doc.msExitFullscreen();
    }
  },

  async [PlayerActions.triggerPlaylistEntry](
    { commit, dispatch, state },
    {
      prev = false,
      triggered = false,
      index = undefined,
      prevIndex = undefined,
    }: { prev: boolean; triggered: boolean; index?: number; prevIndex?: number }
  ) {
    if (state.paused && !triggered) {
      return;
    }
    // console.log("triggerPlaylistEntry", prev, triggered, index, prevIndex);
    dispatch(PlayerActions.setTriggered, triggered);
    if (prevIndex === undefined) {
      commit(PlayerMutations.setPreviousIndex, state.currentIndex);
    } else {
      //console.log(prevIndex);
      commit(PlayerMutations.setPreviousIndex, prevIndex);
    }

    let currentIndex = state.currentIndex;

    let overflow = false;
    if (index !== undefined) {
      currentIndex = index;
    } else {
      if (!prev) {
        currentIndex++;
        if (currentIndex >= state.playlist.length) {
          currentIndex = 0;
          overflow = true;
        }
      } else {
        currentIndex--;
        if (currentIndex < 0) {
          currentIndex = state.playlist.length - 1;
        }
      }
    }
    playlistTimer.clearAll();

    commit(PlayerMutations.setCurrentIndex, currentIndex);
    if (triggered) {
      await dispatch(PlayerActions.setStartTimeForIndex, currentIndex);
    }
    await Vue.nextTick();

    if (overflow) {
      await dispatch(PlayerActions.reshuffleAndLoad);
    }

    if (!urlParamService.loopPlaylist() && overflow && !triggered) {
      dispatch(PlayerActions.setPaused, true);
      dispatch(PlayerActions.setCurrentIndex, 0);
    }

    if ((!triggered && !state.paused) || (!state.paused && triggered)) {
      if (state.playlist[currentIndex].contentItem.type !== "video") {
        console.log("start timer");
        playlistTimer.startTimer(state.playlist[currentIndex].playlistEntry, () => {
          dispatch(PlayerActions.triggerPlaylistEntry, { prev: false, triggered: false });
        });
      }
    }
  },

  async [PlayerActions.setCurrentIndex]({ commit, dispatch, state }, index: number) {
    if (state.currentIndex !== index) {
      commit(PlayerMutations.setPreviousIndex, state.currentIndex);
      commit(PlayerMutations.setCurrentIndex, index);
      await dispatch(PlayerActions.setStartTimeForIndex, index);
    }
  },

  [PlayerActions.setTriggered]({ commit }, payload: boolean) {
    commit(PlayerMutations.setTriggered, payload);
    setTimeout(() => {
      commit(PlayerMutations.setTriggered, false);
    }, 500);
  },

  [PlayerActions.setFullscreenElement]({ commit }, payload: HTMLElement) {
    commit(PlayerMutations.setFullscreenElement, payload);
  },
};
