











import { MediaItem } from "@/interfaces/media-item";
import Component from "vue-class-component";
import { Ref } from "vue-property-decorator";
import { urlParamService } from "../../services/urlParam.service";
import BaseMedia from "./BaseMedia.vue";

@Component({})
export default class ImageMedia extends BaseMedia implements MediaItem {
  @Ref() mediaItem!: HTMLImageElement;
  url = "";
  mounted() {
    const contentItemUrl =
      this.mediaData.contentItem.type === "image"
        ? this.mediaData.contentItem.url
        : this.mediaData.contentItem.image_url
        ? this.mediaData.contentItem.image_url
        : this.mediaData.contentItem.thumbnail_url;
    if (this.index < 5) {
      this.url = this.getResizedImageUrl(`${contentItemUrl}?lastModified=${this.mediaData.contentItem.lastModified}`);
    } else {
      setTimeout(() => {
        this.url = this.getResizedImageUrl(`${contentItemUrl}?lastModified=${this.mediaData.contentItem.lastModified}`);
      }, 150 * (this.index - 4));
    }
  }

  async startMedia() {
    await this.$nextTick();
    this.mediaItem?.classList.add("active");
  }

  async stopMedia() {
    await this.$nextTick();
    if (!this.alwaysVisible) {
      this.mediaItem?.classList.remove("active");
    }
  }

  getResizedImageUrl(url: string): string {
    if (urlParamService.useCdn()) {
      return `https://cdn.contentconvert.alphaport.at/api/v1/image/h_${
        this.calculatedHeight || 720
      },q_80,f_cover/resized.webp?url=${encodeURIComponent(url)}`;
    }
    return url;
  }
}
